import { useState } from "react";
import { API_CONFIG } from "../utils/helpers";
import axios from "axios";
import toast from "react-hot-toast";
import BioprocessReport from "./module2/BioprocessReport";

const generateBaseState = () => {
  return {
    substrate: "",
    product: "",
    microorganism: "",
  };
};

export default function Module2() {
  const [values, setValues] = useState(generateBaseState());
  const [errors, setErrors] = useState(generateBaseState());
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  function validateData() {
    const newErrors = { ...generateBaseState() };
    let isValid = true;
    if (!values.substrate.trim()) {
      newErrors.substrate = "Substrate is required";
      isValid = false;
    }
    if (!values.product.trim()) {
      newErrors.product = "Product is required";
      isValid = false;
    }
    if (!values.microorganism.trim()) {
      newErrors.microorganism = "Microorganism is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      try {
        const { substrate, product, microorganism } = values;
        const response = await axios.get(
          `${API_CONFIG.apiUrl}/kegg/V2/kegg_module?substrate=${substrate}&product=${product}&organism=${microorganism}`
        );
        if (response.status !== 200) {
          throw new Error("An error occurred while fetching data");
        }
        const data = response.data;
        if (data === null) {
          toast.error(
            "No results found with the given input, please try again with different input."
          );
        }
        setData(data);
      } catch (err) {
        console.error(err);
        toast.error("An error occurred while fetching data");
      }
    }
    setIsLoading(false);
  }
  return (
    <section className="md:w-2/3 flex flex-col space-y-10 w-full border px-4 py-8 rounded-lg overflow-scroll">
      <h3 className="text-center text-balance font-bold text-3xl">
        Module 2 Search of Microorganisms for Metabolite Production
      </h3>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-10 w-full">
        <div className="flex flex-col space-y-2">
          <label className="font-medium" htmlFor="substrate">
            Substrate
          </label>
          <input
            className="border p-2 w-full rounded-md"
            type="text"
            name="substrate"
            id="substrate"
            placeholder="Enter Substrate Name"
            value={values.substrate}
            onChange={({ target }) => {
              setErrors({ ...errors, substrate: "" });
              setValues({ ...values, substrate: target.value });
            }}
          />
          {errors.substrate && (
            <span className="text-red-500">{errors.substrate}</span>
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-medium" htmlFor="product">
            Product
          </label>
          <input
            className="border p-2 w-full rounded-md"
            type="text"
            name="product"
            id="product"
            placeholder="Enter Product Name"
            value={values.product}
            onChange={({ target }) => {
              setErrors({ ...errors, product: "" });
              setValues({ ...values, product: target.value });
            }}
          />
          {errors.product && (
            <span className="text-red-500">{errors.product}</span>
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-medium" htmlFor="microorganism">
            Microorganism
          </label>
          <input
            className="border p-2 w-full rounded-md"
            type="text"
            name="microorganism"
            id="microorganism"
            placeholder="Enter Microorganism Name"
            value={values.microorganism}
            onChange={({ target }) => {
              setErrors({ ...errors, microorganism: "" });
              setValues({ ...values, microorganism: target.value });
            }}
          />
          {errors.microorganism && (
            <span className="text-red-500">{errors.microorganism}</span>
          )}
        </div>
        <button
          className={`bg-blue-500 text-white py-2 px-4 rounded-lg flex mx-auto justify-center w-full hover:bg-blue-600 duration-200 ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isLoading}>
          {isLoading ? (
            <img src="../loader.svg" alt="loader" className="h-6 w-6" />
          ) : (
            "Search"
          )}
        </button>
      </form>
      {data && (
        <>
          <header className="space-y-4">
            <h2 className="text-2xl font-bold">Bioprocess Report</h2>
            <div>
              <h3 className="text-xl font-bold">{data?.organism.name}</h3>
              <p className="text-sm text-gray-600">
                {data?.organism.abbreviation} ({data?.organism.organism_code})
              </p>
            </div>
          </header>
          <BioprocessReport report={data?.bioprocess_report} />
        </>
      )}
    </section>
  );
}

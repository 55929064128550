import { useState } from "react";
import aminoacids from "../assets/aminoacids.json";
import ToolsAside from "../components/ToolsAside";

export default function AminoacidCounter() {
  const [sequence, setSequence] = useState("");

  return (
    <main className="max-w-5xl mx-auto p-8 md:flex md:space-x-6 space-y-6 md:space-y-0">
      <ToolsAside id={3} />
      <div className="space-y-10 md:w-3/4">
        <h2 className="text-4xl font-bold">Amino Acid Counter</h2>
        <section className="space-y-4">
          <label htmlFor="fasta" className="font-medium text-2xl">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              if (e.target.value.trim() !== "") {
                const data = e.target.value.toUpperCase().split("\n");
                const res = data.filter(
                  (t) => !t.match(/[^ACDEFGHIKLMNPQRSTVWY]/g)
                );
                if (res.length > 0) setSequence(res.join(""));
              } else if (e.target.value.trim() === "") setSequence("");
              else setSequence((prev) => prev);
            }}
            name="fasta"
            id="fasta"
            cols="30"
            rows="10"
            className="w-full p-4 border-2 rounded-lg"
            placeholder=">Sequence"
          />
        </section>
        <section className="flex flex-col space-y-4">
          <label htmlFor="length" className="font-medium text-2xl">
            Total Number of Molecules
          </label>
          <input
            className="border-2 py-2 px-4 rounded-lg "
            type="text"
            name="length"
            id="length"
            readOnly
            placeholder="# of Molecules"
            value={sequence.length || ""}
          />
        </section>
        <section className="space-y-4">
          <h3 className="font-medium text-2xl">Amino Acids</h3>
          <div className="grid grid-cols-4 gap-4">
            {aminoacids.map((amino) => {
              return (
                <input
                  className="col-span-2 py-2 md:col-span-1 text-center border-2 rounded-lg"
                  readOnly
                  type="text"
                  name={amino}
                  id={amino}
                  key={amino}
                  placeholder={`${"# of " + amino + "'s"}`}
                  value={
                    sequence.match(new RegExp(amino, "g"))
                      ? amino +
                        " = " +
                        sequence.match(new RegExp(amino, "g")).length
                      : ""
                  }
                />
              );
            })}
          </div>
        </section>
      </div>
    </main>
  );
}

export default function SearchButton({ isLoading, disabled = false }) {
  return (
    <button
      type="submit"
      disabled={disabled}
      className="bg-blue-500 text-white rounded-lg p-2 hover:bg-blue-600 duration-200 w-full disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-blue-500">
      {isLoading ? (
        <img className="h-6 mx-auto" src="../loader.svg" alt="loading svg" />
      ) : (
        "Search"
      )}
    </button>
  );
}

import React from "react";

export default function UnderConstruction({
  message = "This view is currently under construction.",
}) {
  return (
    <div className="flex flex-col items-center my-10">
      <h2 className="text-4xl font-bold">Under Construction</h2>
      <img
        src="https://home.geneparadox.com/rsc/work-in-progress.png"
        className="h-80 md:h-96"
        alt=""
      />
      <p>{message}</p>
    </div>
  );
}

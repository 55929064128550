import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
export default function Footer() {
  return (
    <div className="flex flex-col py-4 text-center justify-center md:justify-between md:items-center md:px-10 md:py-0 md:flex-row space-y-4 md:space-y-0 bg-[#f3f6fb]">
      <div className="flex flex-col space-y-2 md:text-left">
        <h4 className="text-lg">Gene Paradox Platform © 2024</h4>
        <a
          href="https://www.geneparadox.com/privacy_policy.html"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-800">
          Privacy Policy
        </a>
        <a
          href="https://geneparadox.com/Terms_and_conditions.html"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-800">
          Terms and Conditions
        </a>
        <a
          href="mailto:info@geneparadox.com"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-800">
          Contact Us
        </a>
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-center space-x-4">
          <a
            href="https://www.linkedin.com/company/geneparadox/mycompany/"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon
              className="h-6 w-6 cursor-pointer hover:scale-125 duration-200 hover:text-black"
              icon={faLinkedin}
            />
          </a>

          <a
            href="https://www.facebook.com/geneparadox"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon
              className="h-6 w-6 cursor-pointer hover:scale-125 duration-200 hover:text-black"
              icon={faFacebook}
            />
          </a>

          <a
            href="https://x.com/_geneparadox?s=21&t=zW4gYMDN0uYPkj8Zzk5WPw"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon
              className="h-6 w-6 cursor-pointer hover:scale-125 duration-200 hover:text-black"
              icon={faTwitter}
            />
          </a>
        </div>
        <img
          className="max-w-[100px] mx-auto"
          src="https://home.geneparadox.com/rsc/aws.png"
          alt="Platform powered by AWS (Amazon Web Services)"
        />
      </div>
    </div>
  );
}

import axios from "axios";

export function setUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function deleteUser() {
  localStorage.removeItem("user");
}

export const API_CONFIG = {
  apiUrl: "https://api.geneparadox.com",
};

export function initAxiosInterceptors() {
  const user = getUser();
  axios.interceptors.request.use((config) => {
    if (user && user.access_token && user.refresh_token && user.user) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (err.response && err.response.status === 401) {
        try {
          const temp_user = getUser();
          const response = await fetch(
            `${API_CONFIG.apiUrl}/users/refresh_token`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(temp_user.refresh_token),
            }
          );
          if (!response.ok) {
            throw err;
          }
          const data = await response.json();
          setUser({
            ...temp_user,
            access_token: data.access_token,
            refresh_token: data.refresh_token,
          });
        } catch (refresh_err) {
          if (refresh_err.response && refresh_err.response.status === 401) {
            deleteUser();
            localStorage.setItem(
              "invalid_credentials",
              "Your session has expired, please login again"
            );
            window.location.href = "/";
          } else {
            return err;
          }
        }
      } else {
        return err;
      }
    }
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_CONFIG, setUser, initAxiosInterceptors } from "../utils/helpers";
import { toast } from "react-hot-toast";

export default function Login({ setUserInformation }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [clicked, setClicked] = useState(false);

  function validateData() {
    let isValid = true;
    setPasswordError("");
    setEmailError("");
    if (password.trim() === "") {
      setPasswordError("Password must not be empty");
      isValid = false;
    }
    if (email.trim() === "") {
      setEmailError("Email must not be empty");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Please validate email format");
      isValid = false;
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    if (validateData()) {
      setClicked(true);
      try {
        const { data } = await toast.promise(
          axios.post(`${API_CONFIG.apiUrl}/users/login`, {
            email,
            password,
          }),
          { loading: "Logging in..." }
        );
        if (data === undefined) {
          throw new Error(
            "An unexpected error has occurred, please try again."
          );
        }
        const userInformation = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          user: data.user,
        };
        setUser(userInformation);
        setUserInformation(userInformation);
        setEmail("");
        setPassword("");
        initAxiosInterceptors();
        window.location.href = "/";
      } catch (err) {
        toast.dismiss();
        console.log(err);
        const errorMessage =
          err.response && err.response.data && err.response.data.detail
            ? err.response.data.detail
            : "An unexpected error has occurred";
        setTimeout(() => toast.error(errorMessage), 500);
      }
      setClicked(false);
    }
  }

  return (
    <section className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="flex justify-center px-6 w-full">
        <div className="bg-white px-6 py-8 w-full shadow-2xl md:w-1/2 xl:w-1/3 rounded-2xl md:rounded-r-none">
          <div className="w-full flex justify-center mb-4">
            <img
              src="https://home.geneparadox.com/rsc/gpp_logo.webp"
              style={{ width: "125px" }}
              alt="logo"
            />
          </div>

          <form onSubmit={handleSubmit} className="space-y-10">
            <p className="text-center font-medium text-2xl">
              Please login to your account
            </p>

            <div className="space-y-2">
              <label className="font-medium" htmlFor="email">
                Email
              </label>
              <input
                type="text"
                id="email"
                autoComplete="email"
                className={`form-control ${emailError ? "is-invalid" : ""}`}
                placeholder="Email address"
                onChange={({ target }) => setEmail(target.value.trim())}
                value={email}
              />
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>
            <div className="space-y-2">
              <label className="font-medium" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                className={`form-control ${
                  passwordError.length > 0 ? "is-invalid" : ""
                }`}
                placeholder="Password"
                onChange={({ target }) => setPassword(target.value)}
                value={password}
              />
              <div>
                <Link
                  className="text-sm hover:underline text-blue-800 hover:scale-105 duration-200 "
                  to="/forgot_password">
                  Forgot your password?
                </Link>
              </div>
              {passwordError && (
                <div className="invalid-feedback">{passwordError}</div>
              )}
            </div>

            <div className="flex flex-col justify-center items-center space-y-4">
              <button
                className="bg-[#0f31ff] py-3 px-8 rounded-xl text-lg text-white hover:-translate-y-1 hover:bg-blue-800 duration-200 disabled:cursor-not-allowed disabled:opacity-70"
                type="submit"
                disabled={clicked}>
                Log in
              </button>
              <Link
                className="text-sm group text-black hover:scale-105 duration-200"
                to="/sign_up">
                Don't have an account?{" "}
                <span className="text-blue-800 group-hover:underline">
                  Sign up
                </span>
              </Link>
            </div>
          </form>
        </div>
        <div className="hidden md:flex w-1/2 xl:w-1/3 bg-[#0f31ff] rounded-r-2xl flex-col justify-center space-y-8 px-8 shadow-2xl">
          <h4 className="text-4xl font-bold text-center text-white">
            We are more than just a company
          </h4>
          <p className="text-xl font-light text-balance text-white">
            At the epicenter of bioinformatic innovation, we extend a warm
            welcome to The Gene Paradox Platform. Here, science meets technology
            to unlock the secrets of the genetic code and open new frontiers in
            biotechnological research.
          </p>
        </div>
      </div>
    </section>
  );
}

import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { API_CONFIG } from "../utils/helpers";

export default function Project() {
  const [projectDetails, setProjectDetails] = useState(null);
  const [canEditNotes, setCanEditNotes] = useState(false);
  const [projectNotes, setProjectNotes] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    axios
      .post(
        `${
          API_CONFIG.apiUrl
        }/users/get_proyect_info?project_id=${searchParams.get("project")}`
      )
      .then((res) => {
        setProjectDetails(res.data);
        setProjectNotes(res.data.notes);
      })
      .catch((e) => {
        toast.error("An unexpected error has occurred");
        navigate("/projects");
      });
  }, [navigate, searchParams]);

  function updateNotes() {
    try {
      axios.post(
        `${API_CONFIG.apiUrl}/users/update_notes?project_id=${projectDetails.project_id}&notes=${projectNotes}`
      );
    } catch (e) {
      toast.error("An unexpected error has occurred");
    }
  }

  function handleDelete() {
    toast.dismiss();
    document.body.style.overflow = "hidden";
    toast.loading(
      <div className="flex flex-col space-y-4 text-center">
        <span className="text-lg">
          Are you sure you want to <strong>delete this project</strong>?
        </span>
        <span className="text-balance text-sm">
          This action <strong>can't be undone</strong>
        </span>
        <div className="w-full flex justify-evenly">
          <button
            onClick={() => {
              toast.dismiss();
              console.log(projectDetails.project_id);
              try {
                axios.post(
                  `${API_CONFIG.apiUrl}/users/delete_project?project_id=${projectDetails.project_id}`
                );
                document.body.style.overflow = "auto";
                window.location.href = "/projects";
              } catch (e) {
                toast.error("An unexpected error has occurred");
              }
              document.body.style.overflow = "auto";
            }}
            className="border-2 hover:bg-red-600 p-2 w-1/3 duration-200 hover:text-white rounded-lg">
            Yes
          </button>
          <button
            onClick={() => {
              toast.dismiss();
              document.body.style.overflow = "auto";
            }}
            className="bg-blue-500 hover:bg-blue-600 p-2 w-1/3 duration-200 text-white rounded-lg">
            No
          </button>
        </div>
      </div>,
      { icon: null }
    );
  }

  return (
    <section className="md:w-1/2 flex flex-col space-y-10 w-full">
      <div className="space-y-6">
        <h3 className="text-3xl font-bold">{projectDetails?.name}</h3>
        {!canEditNotes && (
          <div className="flex items-center space-x-6">
            <h4>Description: {projectDetails?.notes}</h4>
            <img
              src="https://home.geneparadox.com/rsc/pencil.svg"
              alt="Edit button to change project notes"
              className="w-7 h-7 cursor-pointer rounded-lg hover:bg-gray-200 p-1 duration-200"
              onClick={() => setCanEditNotes(true)}
            />
          </div>
        )}
        {canEditNotes && (
          <div className="flex items-center space-x-6">
            <input
              type="text"
              value={projectNotes}
              onChange={(e) => setProjectNotes(e.target.value)}
              className="border-b-2 border-gray-500 px-2 py-1 outline-none w-full"
            />
            <button
              className="bg-blue-500 text-white rounded-lg py-2 px-4 hover:bg-blue-600 duration-200"
              onClick={() => {
                updateNotes();
                setCanEditNotes(false);
                setProjectDetails((prev) => ({
                  ...prev,
                  notes: projectNotes,
                }));
              }}>
              Save
            </button>
          </div>
        )}
        <p className="text-sm">Created: {projectDetails?.createdAt}</p>
        <p className="text-sm">
          Last updated:{" "}
          {projectDetails?.updatedAt || "Project has not been updated"}
        </p>
      </div>
      <div className="w-full border"></div>
      <div className="flex justify-end">
        <button
          className="bg-red-500 text-white rounded-lg py-2 px-4 hover:bg-red-600 duration-200"
          onClick={handleDelete}>
          Delete project
        </button>
      </div>
    </section>
  );
}

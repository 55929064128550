import { useState, useEffect } from "react";
import { API_CONFIG } from "../../utils/helpers";
import axios from "axios";
import toast from "react-hot-toast";
import FastaDisplay from "./ui/FastaDisplay";

export default function SequenceCutter({
  value,
  domainFasta,
  setDomainFasta,
  setCutSequence,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [begin, setBegin] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${API_CONFIG.apiUrl}/genbank/get/{id}?genbank_id=${value}&db=protein`
        );
        setDomainFasta({
          value: data.fasta_sequence.split("\n").slice(1).join("").trim(),
          error: "",
        });
      } catch (e) {
        console.error(e);
        toast.error("An unexpected error has occurred, please try again");
      }
      setIsLoading(false);
    };
    fetchData();
  }, [value, setDomainFasta]);

  function handleClick() {
    const new_begin = parseInt(begin);
    const new_end = parseInt(end);
    const sequence = domainFasta.value;

    if (
      !sequence ||
      isNaN(new_begin) ||
      isNaN(new_end) ||
      new_begin < 1 ||
      new_end > sequence.length ||
      new_begin > new_end
    ) {
      toast.dismiss();
      toast.error("Please insert a valid sequence and range.");
      return;
    }
    setCutSequence(sequence.slice(new_begin - 1, new_end));
  }

  return (
    <>
      <h5 className="font-medium text-xl">Sequence Cutter</h5>
      <FastaDisplay
        isLoading={isLoading}
        fastaSequence={domainFasta.value}
        fastaError={domainFasta.error}
        handleFastaChange={setDomainFasta}
        identifier="domainFasta"
      />
      <div className="flex space-x-10">
        <div className="flex flex-col w-1/2 space-y-2">
          <label htmlFor="begin">Begin</label>
          <input
            id="begin"
            name="begin"
            value={begin}
            onChange={({ target }) => setBegin(target.value)}
            placeholder="Min value: 1"
            type="text"
            className="rounded-lg bg-gray-100 p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none"
          />
        </div>
        <div className="flex flex-col w-1/2 space-y-2">
          <label htmlFor="end">End</label>
          <input
            type="text"
            placeholder={`Max value: ${domainFasta.value.length}`}
            name="end"
            id="end"
            className="rounded-lg bg-gray-100 p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none"
            value={end}
            onChange={({ target }) => setEnd(target.value)}
          />
        </div>
      </div>
      <button
        onClick={handleClick}
        disabled={!domainFasta.value || isLoading}
        className="bg-blue-500 text-white rounded-lg hover:bg-blue-600 duration-200 w-full py-2 cursor-pointer disabled:cursor-not-allowed disabled:hover:bg-blue-500 disabled:opacity-50">
        Cut Sequence
      </button>
    </>
  );
}

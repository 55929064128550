import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import InputInstructions from "../components/InputInstructions";
import InputThanks from "../components/InputThanks";

export default function MolecularDocking({ userInformation }) {
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenThanks, setIsOpenThanks] = useState(false);
  const [hasReadInstructions, setHasReadInstructions] = useState(false);

  const [pdbString, setPdbString] = useState("");
  const [pdbStringErrors, setPdbStringErrors] = useState("");

  const [cid, setCid] = useState("");
  const [cidErrors, setCidErrors] = useState("");

  function validateInput() {
    let isValid = true;
    setPdbStringErrors("");
    setCidErrors("");

    const regex = /^[a-zA-Z]{4}$|^\d{4}$|^[a-zA-Z\d]{4}$/;
    if (!pdbString) {
      isValid = false;
      setPdbStringErrors("PDB String must not be empty");
    } else if (pdbString && !regex.test(pdbString)) {
      isValid = false;
      setPdbStringErrors(
        "PDB String must consist of 4 characters: letters, numbers or both"
      );
    }

    if (!cid) {
      setCidErrors("CID must not be empty.");
      isValid = false;
    }
    return isValid;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (validateInput()) {
      // TODO: Add logic to send data to Google Sheets
      setIsClicked(true);
      try {
        await toast.promise(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const format = (date, locale, options) =>
                new Intl.DateTimeFormat(locale, options).format(date);
              const now = new Date();
              const data = {
                Name: userInformation.name,
                Email: userInformation.email,
                "PDB String": pdbString,
                CID: cid,
                Date: format(now, "en", { dateStyle: "long" }),
              };

              axios
                .post(
                  "https://sheet.best/api/sheets/ebbe0a47-8372-4cd2-89d9-d6b5a4819aa1",
                  data,
                  { timeout: 5000 }
                )
                .then(resolve)
                .catch(reject);
            }, 2000);
          }),
          {
            pending: "Submitting Information...",
            success: "Information sent correcty",
          }
        );
        setIsOpenThanks(true);
        setPdbString("");
        setCid("");
        setPdbStringErrors("");
        setCidErrors("");
      } catch (err) {
        const errorMessage =
          err?.response?.data?.detail || "An unexpected error has occurred";
        setTimeout(() => toast.error(errorMessage), 5000);
      }
      setIsClicked(false);
    }
  }

  return (
    <>
      {!hasReadInstructions && (
        <InputInstructions handleClose={() => setHasReadInstructions(true)} />
      )}
      {isOpenThanks && (
        <InputThanks
          handleClose={() => setIsOpenThanks(false)}
          path="/molecular_docking"
        />
      )}
      <section className="w-full md:flex md:justify-center">
        <form className="my-4 p-6 space-y-8 md:w-1/2" onSubmit={handleSubmit}>
          <h2 className="text-4xl font-bold text-center">Molecular Docking</h2>
          <p className="bg-gray-200 rounded-xl px-4 py-2 italic shadow-xl">
            Our browser-powered platform seamlessly integrates Chimera/Autodock
            Vina's docking engine, letting you analyze molecular interactions in
            record time. Upload, dock, analyze - all with a few clicks. Unleash
            the power of Discovery, one binding pose at a time!
          </p>

          <article>
            <label className="form-label font-medium" htmlFor="pdbString">
              Write down PDB entry
            </label>
            <input
              className={`form-control ${pdbStringErrors ? "is-invalid" : ""}`}
              id="pdbString"
              name="pdbString"
              value={pdbString}
              placeholder="Example: 1A3N"
              maxLength={4}
              onChange={({ target }) => {
                if (pdbStringErrors) {
                  setPdbStringErrors("");
                }
                setPdbString(target.value.trim());
              }}
            />
            {pdbStringErrors && (
              <div className="invalid-feedback">{pdbStringErrors}</div>
            )}
          </article>

          <article>
            <label className="form-label font-medium" htmlFor="cid">
              Write down Compound CID.
            </label>
            <input
              className={`form-control ${cidErrors ? "is-invalid" : ""}`}
              type="text"
              name="cid"
              id="cid"
              value={cid}
              maxLength={10}
              placeholder="CID Input"
              onChange={({ target }) => {
                if (cidErrors) {
                  setCidErrors("");
                }
                const value = target.value.trim();
                if (!isNaN(value[value.length - 1])) {
                  setCid(value);
                } else if (!value) {
                  setCid("");
                } else {
                  setCidErrors("CID mus only consist of numeric digits");
                }
              }}
            />
            {cidErrors && <div className="invalid-feedback">{cidErrors}</div>}
            <ul className="list-disc text-sm text-gray-600 mt-2">
              <li>
                You can obtain it from{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pubchem.ncbi.nlm.nih.gov/"
                  className="text-blue-500 hover:underline">
                  https://pubchem.ncbi.nlm.nih.gov/
                </a>
              </li>
              <li>
                If you have a special compound file, please inquire with us at{" "}
                <a
                  href="mailto:info@geneparadox.com"
                  className="text-blue-500 hover:underline">
                  info@geneparadox.com
                </a>
              </li>
            </ul>
          </article>

          <article className="flex justify-center w-full">
            <button
              disabled={isClicked}
              type="submit"
              className="shadow-xl px-8 py-3 text-white bg-blue-500  rounded-xl hover:opacity-70 hover:-translate-y-1 duration-200 disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:translate-y-0">
              Submit
            </button>
          </article>
        </form>
      </section>
    </>
  );
}

import { useState } from "react";
import axios from "axios";
import { API_CONFIG } from "../../utils/helpers";
import toast from "react-hot-toast";
import SequenceAlignment from "./SequenceAlignment";
import FastaDisplay from "./ui/FastaDisplay";
import ResultItem from "./ui/ResultItem";

export default function FastaSearch({
  geneResults,
  handleGeneResultsChange,
  handleAlignmentResultsChange,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [previosIdentifier, setPreviosIdentifier] = useState("");
  const [geneFasta, setGeneFasta] = useState({ value: "", error: "" });

  async function handleFastaSearch(clikedIdentifier) {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${API_CONFIG.apiUrl}/genbank/get/{id}?genbank_id=${clikedIdentifier}&db=nr`
      );
      setGeneFasta({
        value: data.fasta_sequence,
        error: "",
      });
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error has occurred, please try again");
    }
    setIsLoading(false);
  }

  return (
    <>
      <article className="space-y-2">
        <div className="flex justify-between">
          <h4 className="font-medium text-2xl">Results</h4>
          {geneResults.clikedIdentifier && (
            <span
              onClick={() => {
                setPreviosIdentifier(geneResults.clikedIdentifier);
                handleGeneResultsChange({
                  ...geneResults,
                  clikedIdentifier: "",
                });
              }}
              className="flex space-x-4 cursor-pointer underline text-blue-500 hover:text-blue-600">
              See all results
            </span>
          )}
          {previosIdentifier && (
            <span
              onClick={() => {
                setPreviosIdentifier("");
                handleGeneResultsChange({
                  ...geneResults,
                  clikedIdentifier: previosIdentifier,
                });
              }}
              className="flex space-x-4 cursor-pointer underline text-blue-500 hover:text-blue-600">
              Collpase results
            </span>
          )}
        </div>
        {geneResults.results.length === 0 ? (
          <p className="border rounded-lg px-2 text-center py-6 text-gray-500 cursor-default">
            Please enter your Gene and Organism of interest to display results
          </p>
        ) : (
          <section className="space-y-4">
            {!geneResults.clikedIdentifier &&
              geneResults.results.map((result) => (
                <ResultItem
                  key={result.identifier}
                  result={result}
                  handleClick={() => {
                    handleFastaSearch(result.identifier);
                    setPreviosIdentifier("");
                    handleGeneResultsChange({
                      ...geneResults,
                      clikedIdentifier: result.identifier,
                    });
                    setGeneFasta({ value: "", error: "" });
                  }}
                />
              ))}

            {geneResults.clikedIdentifier &&
              geneResults.results
                .filter(
                  (result) => result.identifier === geneResults.clikedIdentifier
                )
                .map((result) => (
                  <ResultItem
                    key={result.identifier}
                    result={result}
                    handleClick={() => {}}
                  />
                ))}
          </section>
        )}
      </article>
      <SequenceAlignment
        geneFasta={geneFasta}
        handleFastaChange={setGeneFasta}
        handleAlignmentResultsChange={handleAlignmentResultsChange}>
        <FastaDisplay
          isLoading={isLoading}
          fastaSequence={geneFasta.value}
          fastaError={geneFasta.error}
          handleFastaChange={setGeneFasta}
        />
      </SequenceAlignment>
    </>
  );
}

import { useEffect } from "react";
import Axios from "axios";
import { API_CONFIG } from "../utils/helpers";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function ValidateEmail() {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const validateEmail = async () => {
      try {
        const { data } = await Axios.post(
          `${API_CONFIG.apiUrl}/users/complete_register/${code}`
        );
        const user = JSON.stringify(data.user_info);
        sessionStorage.setItem("user", user);
        setTimeout(() => {
          navigate("/confirm");
        }, 2000);
      } catch ({ response }) {
        const errorMessage =
          response && response.data && response.data.detail
            ? response.data.detail
            : "An unexpected error has occured";
        toast.error(errorMessage);
        navigate("/login");
      }
    };

    validateEmail();
  }, [code, navigate]);
}

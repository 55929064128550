export default function SearchButton({ isLoading, disabled = false }) {
  return (
    <button
      type="submit"
      disabled={disabled}
      className="bg-blue-500 text-white rounded-r-lg px-8 hover:bg-blue-600 duration-200 w-1/3 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-blue-500 max-h-[42px]">
      {isLoading ? (
        <img className="h-6 mx-auto" src="../loader.svg" alt="loading svg" />
      ) : (
        "Search"
      )}
    </button>
  );
}

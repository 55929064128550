import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import { CSSTransition } from "react-transition-group";
import ProfilePicture from "../components/ProfilePicture";

const profileImages = [
  { path: "undraw_profile.svg", id: 1 },
  { path: "undraw_profile_1.svg", id: 2 },
  { path: "undraw_profile_2.svg", id: 3 },
  { path: "undraw_profile_3.svg", id: 4 },
];

export default function Profile({
  profileImage,
  setProfileImage,
  userInformation,
}) {
  const [isOpenImageSelector, setIsOpenImageSelector] = useState(false);
  return (
    <>
      <section className="relative flex flex-col items-center justify-center w-full py-10">
        {isOpenImageSelector && (
          <div className="absolute z-50 w-full px-4">
            <div className="relative flex py-12 bg-black justify-evenly rounded-xl md:w-3/4 md:mx-auto">
              {profileImages.map((image) => {
                return (
                  <ProfilePicture
                    key={image.id}
                    setProfileImage={setProfileImage}
                    setIsOpenImageSelector={setIsOpenImageSelector}
                    image={image}
                    isSelected={image.path === profileImage}>
                    {image.path === profileImage ? "Selected" : "Select"}
                  </ProfilePicture>
                );
              })}
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="absolute text-xl text-white cursor-pointer top-2 right-2 hover:opacity-70 md:top-5 md:right-5"
                onClick={() => setIsOpenImageSelector(false)}
              />
            </div>
          </div>
        )}
        <div className=" rounded-2xl border-2 py-10 px-8 space-y-4">
          <div className="flex items-center justify-center w-full">
            <div
              className={`relative flex items-center justify-center ${
                isOpenImageSelector ? "" : "group cursor-pointer"
              }`}
              onClick={() => setIsOpenImageSelector(true)}>
              <img
                src={`https://home.geneparadox.com/rsc/${profileImage}`}
                alt="Profile"
                className="duration-200 rounded-full h-28 md:h-36 group-hover:opacity-70 group-hover:border"
              />
              <div className="absolute hidden space-x-2 group-hover:block">
                <FontAwesomeIcon icon={faPencil} className="pencil-icon" />
                <span className="text-sm md:text-lg">Edit Image</span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className="flex flex-col mt-6 space-y-6">
              <div className="flex items-baseline w-full space-x-4">
                <h3 className="text-lg font-bold md:text-xl">Name:</h3>
                <span className="text-xl md:text-2xl">
                  {userInformation.name}
                </span>
              </div>
              <div className="flex items-baseline w-full space-x-4">
                <h3 className="text-lg font-bold md:text-xl">Email:</h3>
                <span className="text-xl md:text-2xl">
                  {userInformation.email}
                </span>
              </div>

              <div className="flex items-baseline w-full space-x-4">
                <h3 className="text-lg font-bold md:text-xl">Phone:</h3>
                <span className="text-xl md:text-2xl">
                  {userInformation.phone}
                </span>
              </div>

              <div className="flex items-baseline w-full space-x-4">
                <h3 className="text-lg font-bold md:text-xl">Company:</h3>
                <span className="text-xl md:text-2xl">
                  {userInformation.company}
                </span>
              </div>

              <div className="flex items-baseline w-full space-x-4">
                <h3 className="text-lg font-bold md:text-xl">Industry:</h3>
                <span className="text-xl md:text-2xl">
                  {userInformation.industry}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

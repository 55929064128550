import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  // faCog,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { deleteUser } from "../utils/helpers";

export default function Navbar({ profileImage, username }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    toast.dismiss();
    document.body.style.overflow = "hidden";
    toast.loading(
      <div className="flex flex-col space-y-4 text-center px-8">
        <span className="text-lg">
          Are you sure you want to <strong>log out</strong>?
        </span>
        <div className="w-full flex justify-evenly">
          <button
            onClick={() => {
              navigate("/");
              toast.dismiss();
              document.body.style.overflow = "auto";
              deleteUser();
              window.location.reload();
            }}
            className="border-2 hover:bg-red-600 p-2 w-1/3 duration-200 hover:text-white rounded-lg">
            Yes
          </button>
          <button
            onClick={() => {
              toast.dismiss();
              document.body.style.overflow = "auto";
            }}
            className="bg-blue-500 hover:bg-blue-600 p-2 w-1/3 duration-200 text-white rounded-lg">
            No
          </button>
        </div>
      </div>,
      { icon: null }
    );
  };
  return (
    <>
      <header>
        <nav className="flex flex-col bg-black items-center py-6 space-y-2 px-2 lg:hidden justify-between">
          <Link
            className="text-3xl text-white font-medium"
            to="/"
            onClick={() => {
              setIsOpenMenu(false);
              setIsOpenModal(false);
            }}>
            Gene Paradox Platform
          </Link>
          {!isOpenMenu ? (
            <img
              src="https://home.geneparadox.com/rsc/menu.svg"
              alt="Menu icon for responsive menu"
              className="h-10 hover:cursor-pointer hover:bg-gray-900 p-1 rounded-md"
              onClick={() => setIsOpenMenu((prev) => !prev)}
            />
          ) : (
            <img
              src="https://home.geneparadox.com/rsc/x.svg"
              alt="Closing icon for responsive menu"
              className="h-10 hover:cursor-pointer hover:bg-gray-900 p-1 rounded-md"
              onClick={() => setIsOpenMenu((prev) => !prev)}
            />
          )}
          <div
            className={`space-y-3 py-2 px-6 flex-col text-center w-full ${
              isOpenMenu ? "flex" : "hidden"
            }`}>
            <Link
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenModal(false);
              }}
              className="text-sm font-medium uppercase text-white hover:scale-105 hover:text-gray-500"
              to="/">
              Home
            </Link>
            <Link
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenModal(false);
              }}
              className="text-sm font-medium uppercase text-white hover:scale-105 hover:text-gray-500"
              to="/projects">
              My Projects
            </Link>
            <Link
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenModal(false);
              }}
              className="text-sm font-medium uppercase text-white hover:scale-105 hover:text-gray-500"
              to="/tools">
              Tools
            </Link>
            <hr className="h-1 w-full bg-white rounded-full" />
            <div className="text-center flex flex-col space-y-4 pt-2">
              <Link
                onClick={() => {
                  setIsOpenMenu(false);
                  setIsOpenModal(false);
                }}
                className="text-sm font-medium uppercase text-white hover:scale-105 hover:text-gray-500 "
                to="/profile">
                Profile
              </Link>
              <span
                onClick={() => {
                  setIsOpenMenu(false);
                  setIsOpenModal(false);
                  handleLogout();
                }}
                className="text-sm font-medium uppercase text-white hover:scale-105 hover:text-gray-500 cursor-pointer">
                Logout
              </span>
            </div>
          </div>
        </nav>

        <nav className="hidden bg-black items-center justify-between py-4 space-x-4 px-8 lg:flex">
          <Link
            className="text-4xl text-white font-medium"
            to="/"
            onClick={() => {
              setIsOpenMenu(false);
              setIsOpenModal(false);
            }}>
            Gene Paradox Platform
          </Link>
          <div className="flex flex-col text-white py-2 md:flex-row md:justify-center md:py-0 space-y-2 md:space-y-0 md:space-x-20 text-center">
            <Link
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenModal(false);
              }}
              className="text-sm font-medium uppercase hover:text-white hover:scale-105 duration-100 hover:opacity-60 flex justify-center items-center"
              to="/">
              Home
            </Link>
            <Link
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenModal(false);
              }}
              className="text-sm font-medium uppercase hover:text-white hover:scale-105 duration-100 hover:opacity-60 flex justify-center items-center"
              to="/projects">
              My Projects
            </Link>
            <Link
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenModal(false);
              }}
              className="text-sm font-medium uppercase hover:text-white hover:scale-105 duration-100 hover:opacity-60 flex justify-center items-center"
              to="/tools">
              Tools
            </Link>
          </div>
          <div className="relative flex flex-col items-center space-y-2 md:space-y-0">
            <img
              src={`https://home.geneparadox.com/rsc/${profileImage}`}
              alt="Profile"
              className="w-16 h-16 rounded-full hover:opacity-60 hover:cursor-pointer md:mb-0"
              onClick={() => setIsOpenModal((prev) => !prev)}
            />
            <p className="text-white text-center">{username}</p>

            {isOpenModal && (
              <div className="bg-white text-black absolute rounded-lg flex flex-col py-3 px-4 border shadow-lg top-[6rem] md:right-0 space-y-4 z-50">
                <Link
                  className="flex items-center justify-start space-x-2 hover:cursor-pointer hover:text-black hover:opacity-60"
                  to="/profile"
                  onClick={() => {
                    setIsOpenMenu(false);
                    setIsOpenModal(false);
                  }}>
                  <FontAwesomeIcon icon={faUser} className="" />
                  <span>Profile</span>
                </Link>
                <hr className="bg-black" />
                <div
                  className="flex items-center justify-start space-x-2 hover:cursor-pointer hover:opacity-60"
                  onClick={() => {
                    setIsOpenMenu(false);
                    setIsOpenModal(false);
                    handleLogout();
                  }}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="dropdown-icon"
                  />
                  <span>Logout</span>
                </div>
              </div>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "../views/Profile";
import GeneInputForm from "../views/GeneInputForm";
import MolecularDocking from "../views/MolecularDocking";
import Home from "../views/Home";
import UnderConstruction from "../views/UnderConstruction";
import Projects from "../views/Projects";
import Tools from "../views/Tools";
import ProjectDetails from "../views/ProjectDetails";

import SequenceCutter from "../views/SequenceCutter";
import NucleotideCounter from "../views/NucleotideCounter";
import AminoacidCounter from "../views/AminoacidCounter";
import NucleotideToAminoacid from "../views/NucleotideToAminoacid";
import FastaConverter from "../views/FastaConverter";
import GRASOrganism from "../views/GRASOrganism";

export default function LoginRoutes({
  profileImage,
  setProfileImage,
  userInformation,
}) {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="/tools" element={<Tools />} />
      <Route path="/tools/sequence_cutter" element={<SequenceCutter />} />
      <Route path="/tools/nucleotide_counter" element={<NucleotideCounter />} />
      <Route path="/tools/aminoacid_counter" element={<AminoacidCounter />} />
      <Route path="/tools/fasta_converter" element={<FastaConverter />} />
      <Route path="/tools/gras_organism_list" element={<GRASOrganism />} />
      <Route
        path="/tools/nucleotide_translator"
        element={<NucleotideToAminoacid />}
      />

      <Route path="/under_construction" element={<UnderConstruction />} />
      <Route path="/projects" element={<Projects />} />
      <Route
        path="/gene_input_form"
        element={<GeneInputForm userInformation={userInformation} />}
      />
      <Route
        path="/molecular_docking"
        element={<MolecularDocking userInformation={userInformation} />}
      />
      <Route
        path="/profile"
        element={
          <Profile
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            userInformation={userInformation}
          />
        }
      />
      <Route path="/project-details" element={<ProjectDetails />} />
    </Routes>
  );
}

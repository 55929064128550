import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import axios from "axios";
import { API_CONFIG } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [isOpenAddNewProject, setIsOpenAddNewProject] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  function agregarCero(num) {
    return num < 10 ? "0" + num : num;
  }

  useEffect(() => {
    const loadProjects = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.post(
          `${API_CONFIG.apiUrl}/users/get_user_projects`
        );
        setProjects(data);
      } catch (err) {
        console.error(err);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
    loadProjects();
  }, []);

  function validateData() {
    let isValid = true;
    setNameError("");
    setNotesError("");
    if (!name) {
      isValid = false;
      setNameError("Project name is required");
    }
    if (!notes) {
      isValid = false;
      setNotesError("Project notes are required");
    }
    return isValid;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      const newProject = {
        name,
        notes,
      };
      try {
        await axios.post(
          `${API_CONFIG.apiUrl}/users/create_project`,
          newProject
        );
        newProject.createdAt = new Date().toISOString();
        setProjects((prev) => [...prev, newProject]);
        setIsOpenAddNewProject(false);
        setName("");
        setNotes("");
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      {isOpenAddNewProject && (
        <Modal
          handleClose={() => {
            setIsOpenAddNewProject(false);
            setName("");
            setNotes("");
            setNameError("");
            setNotesError("");
          }}>
          <div className="w-full text-center md:flex md:flex-col md:items-center md:justify-center mt-12">
            <h2 className="text-3xl font-bold">Create New Project</h2>
            <br />
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center w-full space-y-6">
              <div className="w-full flex flex-col items-start">
                <label htmlFor="projectName" className="px-2 text-xl">
                  Project Name *
                </label>
                <input
                  name="projectName"
                  id="projectName"
                  type="text"
                  placeholder="(max: 50 characters)"
                  className="w-full p-2 text-black bg-gray-100 rounded-lg"
                  onChange={(e) => {
                    e.target.value.length > 50
                      ? setNameError("Character limit reached")
                      : setNameError("");
                    setName((prev) =>
                      e.target.value.length > 50 ? prev : e.target.value
                    );
                  }}
                  value={name}
                />
                {nameError && <p className="text-red-500">{nameError}</p>}
              </div>
              <div className="w-full flex flex-col items-start">
                <label htmlFor="projectNotes" className="px-2 text-xl">
                  Project Notes *
                </label>
                <textarea
                  name="projectNotes"
                  id="projectNotes"
                  placeholder="(max: 300 characters)"
                  className="w-full p-2 text-black bg-gray-100 rounded-lg"
                  onChange={(e) => {
                    e.target.value.length > 300
                      ? setNotesError("Character limit reached")
                      : setNotesError("");
                    setNotes((prev) =>
                      e.target.value.length > 300 ? prev : e.target.value
                    );
                  }}
                  value={notes}
                />
                {notesError && <p className="text-red-500">{notesError}</p>}
              </div>

              <button
                className={`bg-blue-500 text-white py-3 px-4 rounded-lg flex mx-auto justify-center w-1/3 hover:bg-blue-600 duration-200 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}>
                {isLoading ? (
                  <img src="../loader.svg" alt="loader" className="h-6 w-6" />
                ) : (
                  "Create Project"
                )}
              </button>
            </form>
            <br />
            <p>* Required field</p>
          </div>
        </Modal>
      )}
      <main
        className={`overflow-scroll mx-auto ${
          isLoading
            ? "bg-gray-400 w-full flex justify-center items-center min-h-[70vh]"
            : "flex flex-col py-6 space-y-6 px-8 min-h-[70vh] justify-center"
        }
        ${!isLoading && "max-w-5xl "}
        `}>
        {isLoading && <img src="./loader.svg" alt="loading" />}
        {!isLoading && projects?.length > 0 && (
          <div className="flex flex-col justify-between space-y-6 md:space-y-0 md:flex-row">
            <h2 className="text-center text-3xl font-bold">My Projects</h2>
            <button
              className="py-2 px-6 bg-blue-500 text-white rounded-lg hover:bg-blue-600 hover:-translate-y-1 duration-200"
              onClick={() => setIsOpenAddNewProject((prev) => !prev)}>
              New Project
            </button>
          </div>
        )}
        {!isLoading && projects?.length === 0 && (
          <h2 className="text-center text-3xl font-bold">My Projects</h2>
        )}
        {!isLoading && (
          <section className="space-y-6 lg:space-y-10 flex flex-col overflow-scroll">
            {projects?.length === 0 && (
              <>
                <h3 className="text-center text-xl font-medium">
                  You don't have any projects yet
                </h3>
                <article className="bg-gray-100 py-4 rounded-xl flex flex-col items-center w-full space-y-6 px-10 xl:w-1/2 mx-auto">
                  <p className="text-center text-lg">
                    Start by creating a new project
                  </p>
                  <button
                    className="py-2 px-6 bg-blue-500 text-white rounded-xl hover:bg-blue-600 hover:-translate-y-1 duration-200"
                    onClick={() => setIsOpenAddNewProject((prev) => !prev)}>
                    Create New Project
                  </button>
                </article>
              </>
            )}
            {projects?.length > 0 && (
              <table className="w-full border">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 border">Name</th>
                    <th className="p-2 border">Created on</th>
                    <th className="p-2 border">Last updated</th>
                    <th className="p-2 border">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {projects?.map((project) => (
                    <tr
                      key={project?.project_id}
                      className="hover:bg-gray-100"
                      onClick={() => {
                        localStorage.setItem(
                          "project",
                          JSON.stringify(project)
                        );
                        navigate(
                          `/project-details/?project=${project?.project_id}`
                        );
                      }}>
                      <td className="p-2 border text-blue-500 underline hover:cursor-pointer hover:text-blue-600">
                        {project?.name}
                      </td>
                      <td className="p-2 border">
                        {agregarCero(new Date(project?.createdAt).getDate()) +
                          "/" +
                          agregarCero(
                            new Date(project?.createdAt).getMonth() + 1
                          ) +
                          "/" +
                          new Date(project?.createdAt).getFullYear()}
                      </td>
                      <td className="p-2 border">
                        {project?.updatedAt
                          ? agregarCero(
                              new Date(project?.createdAt).getHours()
                            ) +
                            ":" +
                            agregarCero(
                              new Date(project?.createdAt).getMinutes()
                            ) +
                            " " +
                            (agregarCero(
                              new Date(project?.createdAt).getDate()
                            ) +
                              "/" +
                              agregarCero(
                                new Date(project?.createdAt).getMonth() + 1
                              ) +
                              "/" +
                              new Date(project?.createdAt).getFullYear())
                          : "Never updated"}
                      </td>
                      <td className="p-2 border">{project?.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </section>
        )}
      </main>
    </>
  );
}

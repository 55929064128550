import { useNavigate } from "react-router-dom";

export default function ModuleButton({
  children,
  disabled = false,
  path = "/",
  isSelected = false,
}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <>
      <div className="hidden md:block w-full">
        <button
          className={`w-full border ${
            !isSelected && !disabled && "hover:bg-blue-600 hover:text-white"
          } duration-200 p-2 rounded-lg ${
            isSelected && "bg-blue-500 text-white hover:cursor-not-allowed"
          } ${disabled && "bg-gray-300 text-gray-500 cursor-not-allowed"}`}
          disabled={disabled}
          onClick={handleNavigate}>
          {children}
        </button>
      </div>

      <div className="block md:hidden">
        <button
          className={`w-full border flex flex-col items-center justify-center space-y-2 ${
            !isSelected && !disabled && "hover:bg-blue-600 hover:text-white"
          } duration-200 p-2 rounded-lg ${
            isSelected && "bg-blue-500 text-white hover:cursor-not-allowed"
          } ${disabled && "bg-gray-300 text-gray-500 cursor-not-allowed"}`}
          disabled={disabled}
          onClick={handleNavigate}>
          {children}
        </button>
      </div>
    </>
  );
}

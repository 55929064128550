import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NotLoggedInRoutes from "./routes/NotLoggedInRoutes";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Toaster, toast } from "react-hot-toast";
import {
  deleteUser,
  getUser,
  API_CONFIG,
  initAxiosInterceptors,
} from "./utils/helpers";
import axios from "axios";
import CookiesNotice from "./components/ui/CookiesNotice";

initAxiosInterceptors();

export default function App() {
  const [userInformation, setUserInformation] = useState({});
  const [profileImage, setProfileImage] = useState("undraw_profile.svg");
  const [hasAgreed, setHasAgreed] = useState(() => {
    return JSON.parse(localStorage.getItem("hasAgreed")) || false;
  });

  const handleLogout = (message = "You have been logged out") => {
    setUserInformation({});
    deleteUser();
    toast.success(message);
  };

  useEffect(() => {
    if (localStorage.getItem("invalid_credentials")) {
      toast.error(localStorage.getItem("invalid_credentials"));
      localStorage.removeItem("invalid_credentials");
    }
    const user = getUser();
    if (!user) {
      setUserInformation({});
      deleteUser();
      return;
    }
    const loadUser = async () => {
      setUserInformation(user);
      try {
        await axios.get(`${API_CONFIG.apiUrl}/users/whoAmI`);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          handleLogout("Your session has expired, please login again");
        }
        toast.error("An unexpected error has occurred, please try again");
      }
    };
    loadUser();
  }, []);

  return (
    <>
      <Toaster position="top-center" />
      <Router>
        {userInformation.access_token && userInformation.refresh_token ? (
          <>
            {!hasAgreed && <CookiesNotice setHasAgreed={setHasAgreed} />}
            <Navbar
              profileImage={profileImage}
              handleLogout={handleLogout}
              username={
                userInformation.user.name
                  .split(" ")[0]
                  .toUpperCase()
                  .slice(0, 1) +
                userInformation.user.name.split(" ")[0].toLowerCase().slice(1)
              }
            />
            <LoggedInRoutes
              userInformation={userInformation.user}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
            />
            <Footer />
          </>
        ) : (
          <NotLoggedInRoutes setUserInformation={setUserInformation} />
        )}
      </Router>
    </>
  );
}

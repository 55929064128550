import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_CONFIG } from "../utils/helpers";
import { toast } from "react-hot-toast";

export default function FormRecover() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [clicked, setClicked] = useState(false);

  function validateData() {
    let isValid = true;
    setEmailError("");
    if (email.trim() === "") {
      setEmailError("Email must not be empty");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Please validate email format");
      isValid = false;
    }
    return isValid;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (validateData()) {
      setClicked(true);
      try {
        await toast.promise(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              axios
                .post(`${API_CONFIG.apiUrl}/users/forgot_password`, email, {
                  timeout: 5000,
                })
                .then(resolve)
                .catch(reject);
            }, 2000);
          }),
          {
            pending: "Sending email...",
            success: `An email has been sent to: ${email}`,
          }
        );
        navigate("/login");
      } catch ({ response }) {
        const errorMessage =
          response && response.data && response.data.detail
            ? response.data.detail
            : "An unexpected error has occured";
        setTimeout(() => {
          toast.error(errorMessage);
          setClicked(false);
        }, 500);
      }
    }
  }
  return (
    <section className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <form
        className="bg-white rounded-xl shadow-2xl py-8 px-4 space-y-10 w-full md:max-w-2xl"
        onSubmit={handleSubmit}>
        <h1 className="text-black text-center font-bold text-3xl">
          Password Recovery
        </h1>
        <p className="italic bg-gray-200 text-balance p-2 rounded-md shadow-sm border-2 border-gray-300 text-center">
          <span className="font-bold tracking-wide">Note:</span> After entering
          your email address, you will recieve a code on that same email address
          to change your password.
        </p>
        <div className="space-y-2">
          <label className="font-medium" htmlFor="email">
            * Email Address
          </label>
          <input
            type="text"
            className={`form-control ${emailError ? "is-invalid" : ""}`}
            placeholder="Type your email address here..."
            value={email}
            onChange={({ target }) => {
              setEmail(target.value.trim());
              setEmailError("");
            }}
          />
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white font-medium py-2 px-6 rounded hover:bg-blue-600 hover:-translate-y-1 duration-200 w-full sm:w-1/2 md:py-3 md:px-8 md:rounded-lg"
            disabled={clicked}>
            Send recovery code
          </button>
        </div>
        <p className="text-sm text-center">* Required field</p>
      </form>
    </section>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_CONFIG } from "../utils/helpers";
import { toast } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    } else {
      navigate("/");
    }
  }, [navigate]);

  // input variables
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const captcha = useRef(null);

  // error variables
  const [passwordErrors, setPasswordErrors] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [industryError, setIndustryError] = useState("");
  const [privacyPolicyError, setPrivacyPolicyError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");

  function validateData() {
    let isValid = true;
    // reset Errors
    setPasswordErrors([]);
    setNameError("");
    setPhoneError("");
    setCompanyError("");
    setIndustryError("");
    setPrivacyPolicyError("");

    if (name.trim() === "") {
      isValid = false;
      setNameError("Name must not be empty");
    }
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      isValid = false;
      setPhoneError("Phone number must have 10 digits");
    }
    if (company.trim() === "") {
      isValid = false;
      setCompanyError("Company must not be empty");
    }
    if (industry.trim() === "") {
      isValid = false;
      setIndustryError("Industry must not be empty");
    }
    if (password.trim() === "" || password.trim() !== "") {
      if (password.length < 8) {
        setPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least 8 characters",
        ]);
        isValid = false;
      }
      if (!/[a-z]/.test(password)) {
        setPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one lowercase letter",
        ]);
        isValid = false;
      }
      if (!/[A-Z]/.test(password)) {
        setPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one uppercase letter",
        ]);
        isValid = false;
      }
      if (!/\d/.test(password)) {
        setPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one digit",
        ]);
        isValid = false;
      }
      if (!/[@$!%*?&#]/.test(password)) {
        setPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one special character: @$!%*?&#",
        ]);
        isValid = false;
      }
    }
    if (!privacyPolicy) {
      isValid = false;
      setPrivacyPolicyError(
        "You must agree with the privacy policy in order to use our services"
      );
    }
    if (!recaptcha) {
      isValid = false;
      setRecaptchaError(
        "Please verify with Captcha in order to use our services"
      );
    }
    return isValid;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (validateData()) {
      setClicked(true);
      try {
        await toast.promise(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              axios
                .post(
                  `${API_CONFIG.apiUrl}/users/confirm/${user.confirm_token}`,
                  {
                    email: user.email,
                    password,
                    name,
                    phone,
                    company,
                    industry,
                  },
                  { timeout: 5000 }
                )
                .then(resolve)
                .catch(reject);
            }, 2000);
          }),
          {
            pending: "Creating user...",
            success: "Successfully created",
          }
        );
        setName("");
        setPhone("");
        setPassword("");
        setCompany("");
        setIndustry("");
        setPasswordErrors([]);
        sessionStorage.removeItem("user");
        navigate("/login");
      } catch ({ response }) {
        const errorMessage =
          response && response.data && response.data.detail
            ? response.data.detail
            : "An unexpected error has occured";
        setTimeout(() => toast.error(errorMessage), 5000);
      }
      setClicked(false);
    }
  }

  return (
    <section className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-8">
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-2xl py-8 px-4 space-y-10 w-full md:max-w-2xl">
        <h1 className="text-black text-center font-bold text-3xl text-balance">
          Finish setting up your account
        </h1>
        <div className="form-outline">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            type="text"
            id="email"
            className="form-control"
            disabled
            value={user.email}
          />
        </div>

        <div className="space-y-2">
          <label className="form-label" htmlFor="name">
            Your Name
          </label>
          <input
            type="text"
            placeholder="Enter your full name"
            id="name"
            className={`form-control ${nameError ? "is-invalid" : ""}`}
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
          {nameError && <div className="invalid-feedback">{nameError}</div>}
        </div>

        <div className="space-y-2">
          <label className="form-label" htmlFor="password">
            Create a password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            className={`form-control ${
              passwordErrors.length > 0 ? "is-invalid" : ""
            }`}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
          {passwordErrors.length > 0 && (
            <ul className="invalid-feedback">
              {passwordErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
        </div>

        <div className="space-y-2">
          <label className="form-label" htmlFor="phone">
            Phone number
          </label>
          <input
            type="text"
            placeholder="Enter your phone number"
            id="phone"
            className={`form-control ${phoneError ? "is-invalid" : ""}`}
            value={phone}
            onChange={({ target }) => setPhone(target.value.trim())}
          />
          {phoneError && <div className="invalid-feedback">{phoneError}</div>}
        </div>

        <div className="space-y-2">
          <label className="form-label" htmlFor="company">
            Name of your Company
          </label>
          <input
            type="text"
            id="company"
            placeholder="Enter the name of your company"
            className={`form-control ${companyError ? "is-invalid" : ""}`}
            value={company}
            onChange={({ target }) => setCompany(target.value)}
          />
          {companyError && (
            <div className="invalid-feedback">{companyError}</div>
          )}
        </div>

        <div className="space-y-2">
          <label className="form-label" htmlFor="industry">
            Company Industry
          </label>
          <input
            type="text"
            id="industry"
            placeholder="Enter your company's industry"
            className={`form-control ${industryError ? "is-invalid" : ""}`}
            value={industry}
            onChange={({ target }) => setIndustry(target.value)}
          />
          {industryError && (
            <div className="invalid-feedback">{industryError}</div>
          )}
        </div>

        <div className="form-check mb-3">
          <input
            className={`form-check-input ${
              privacyPolicyError && !privacyPolicy ? "is-invalid" : ""
            }`}
            type="checkbox"
            id="flexCheck"
            checked={privacyPolicy}
            onChange={() => setPrivacyPolicy((prev) => !prev)}
          />
          <label className="form-check-label" htmlFor="flexCheck">
            I have read and agree to the{" "}
            <a
              className="text-blue-500 cursor-pointer"
              href="https://www.geneparadox.com/privacy_policy.pdf"
              target="_blank"
              rel="noreferrer">
              privacy policy
            </a>
            .
          </label>
          {privacyPolicyError && !privacyPolicy && (
            <div className="invalid-feedback">{privacyPolicyError}</div>
          )}
        </div>

        <div className="flex flex-col items-center">
          <ReCAPTCHA
            ref={captcha}
            sitekey="6Lf-NbQoAAAAAMLiV3v5uoIcIyEBXo-ptrDfWtV2"
            onChange={() => {
              setRecaptcha(captcha.current.getValue());
              setRecaptchaError("");
            }}
          />
          {recaptchaError && (
            <span className="text-xs text-red-600">{recaptchaError}</span>
          )}
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white font-medium py-2 px-6 rounded hover:bg-blue-600 hover:-translate-y-1 duration-200 w-full sm:w-1/2"
            disabled={clicked}>
            Complete registration
          </button>
        </div>
        <p className="text-sm text-center">* Required field</p>
      </form>
    </section>
  );
}

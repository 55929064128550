import modules from "../assets/modules.json";
import ModuleButton from "./ModuleButton";

export default function ModuleSelector({ projectId, selectedModule }) {
  return (
    <aside className="bg-gray-100 py-12 rounded-xl flex flex-col items-center w-full space-y-9 px-10 md:w-1/3 h-fit">
      <h3 className="text-3xl font-bold">Modules</h3>

      <section className="w-full space-y-4">
        {selectedModule && (
          <ModuleButton
            content={"View Project Information"}
            path={`?project=${projectId}`}>
            Project Details
          </ModuleButton>
        )}
        {modules.map((module) => (
          <ModuleButton
            key={module.short_name}
            disabled={module.disabled}
            isSelected={module.short_name === selectedModule}
            path={`?project=${projectId}&module=${encodeURIComponent(
              module.short_name
            )}`}>
            {module.description}
          </ModuleButton>
        ))}
      </section>
    </aside>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function ProfilePicture({
  setProfileImage,
  image,
  setIsOpenImageSelector,
  isSelected,
  children,
}) {
  return (
    <div
      className={`flex flex-col items-center group ${
        isSelected ? "" : "cursor-pointer"
      }`}
      onClick={() => {
        if (isSelected) return;
        setProfileImage(image.path);
        setIsOpenImageSelector(false);
      }}>
      <img
        src={`https://home.geneparadox.com/rsc/${image.path}`}
        alt="Profile"
        className={`w-16 h-16 md:h-24 md:w-24 ${
          isSelected ? " opacity-50" : "group-hover:opacity-70"
        }`}
      />
      <div
        className={`${isSelected ? "text-green-500" : "text-white"} space-x-2`}>
        <FontAwesomeIcon icon={faCheckCircle} />
        <span className="font-light md:text-xl">{children}</span>
      </div>
    </div>
  );
}

import { useState } from "react";
import { API_CONFIG } from "../utils/helpers";
import axios from "axios";
import toast from "react-hot-toast";
import SearchForm from "./module3/ui/SearchForm";
import SearchInput from "./module3/ui/SearchInput";
import SearchButton from "./module3/ui/SearchButton";
import PaginatedTable from "./ui/PaginatedTable";

export default function Module3() {
  const [enzyme, setEnzyme] = useState({
    value: "",
    error: "",
  });
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const enzymeId = parseInt(enzyme.value.split(" ").pop()) || null;
    if (enzyme.value.trim() === "" || !enzymeId) {
      setEnzyme({ ...enzyme, error: "Please enter a valid enzyme name" });
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.get(
        `${API_CONFIG.apiUrl}/brenda/get_organisms?rn_id=${enzymeId}`
      );
      if (response.status === 200) {
        setResults(response.data);
      }
    } catch (error) {
      error.response
        ? toast.error(`Error: ${error.response.data.detail}`)
        : toast.error("An error occurred while searching for the enzyme.");
    }
    setIsLoading(false);
  }

  return (
    <section className="md:w-2/3 flex flex-col space-y-10 w-full border px-4 py-8 rounded-lg overflow-scroll">
      <h3 className="text-center text-balance font-bold text-3xl">
        Module 3 Characteristics for the production of a target enzyme
      </h3>
      <SearchForm
        labelValue={"Enzyme name"}
        identifier={"enzyme"}
        handleSubmit={handleSubmit}
        error={enzyme.error}>
        <SearchInput
          identifier={"enzyme"}
          placeholder={"Target enzyme"}
          value={enzyme.value}
          setValue={setEnzyme}
          disabled={isLoading}
          show={true}
          double={false}
        />
        {enzyme.error && <p className="text-red-500">{enzyme.error}</p>}
        <SearchButton disabled={isLoading} isLoading={isLoading}></SearchButton>
      </SearchForm>
      {results.length > 0 && <PaginatedTable items={results} />}
    </section>
  );
}

import Project from "../components/Project";
import ModuleSelector from "../components/ModuleSelector";
import { useSearchParams, useNavigate } from "react-router-dom";
import Module1 from "../components/Module1";
import Module2 from "../components/Module2";
import Module3 from "../components/Module3";

export default function ProjectDetails() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");
  const moduleId = searchParams.get("module");

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/projects");
  };

  return (
    <>
      <main className="w-full flex flex-col mx-auto px-8 py-10">
        <button
          className="flex space-x-2 duration-200 border rounded-lg py-2 px-4 hover:bg-gray-100 w-fit"
          onClick={handleNavigate}>
          <img
            src="https://home.geneparadox.com/rsc/go-back.svg"
            className="h-6 w-6"
            alt=""
          />
          <span>Go back to projects</span>
        </button>
        <div className="flex md:flex-row md:space-x-8 md:space-y-0 py-10 flex-col-reverse space-y-8 space-y-reverse w-full">
          <ModuleSelector projectId={projectId} selectedModule={moduleId} />
          {!moduleId && <Project />}
          {moduleId === "1" && <Module1 />}
          {moduleId === "2" && <Module2 />}
          {moduleId === "3" && <Module3 />}
        </div>
      </main>
    </>
  );
}

import { useState } from "react";
import axios from "axios";
import { API_CONFIG } from "../../utils/helpers";
import toast from "react-hot-toast";
import SearchButton from "./ui/SearchButton";
import SearchForm from "./ui/SearchForm";

export default function GeneSearch({ handleGeneResultsChange }) {
  const [gene, setGene] = useState({ value: "", error: "" });
  const [isLoading, setIsLoading] = useState(false);

  async function handleGeneSearch(e) {
    e.preventDefault();
    if (gene.value.trim() === "") {
      setGene({ ...gene, error: "Gene and organism must not be empty" });
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${API_CONFIG.apiUrl}/genbank/search?query=${gene.value
          .split("(")[0]
          .trim()}`
      );
      if (!data?.length) {
        toast.dismiss();
        toast.error("No results found have been found for this search");
      }
      handleGeneResultsChange({ clickedIdentifier: "", results: data || [] });
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error has occurred, please try again");
    }
    setIsLoading(false);
  }

  return (
    <article className="space-y-6">
      <h4 className="font-medium text-2xl">Gene and Organism Search</h4>
      <p>
        In this module, the input is the information of the protein you wish to
        produce, along with information about the desired receptor microoganism.
      </p>
      <SearchForm
        labelValue={"Enter gene and organism"}
        identifier={"gene"}
        handleSubmit={handleGeneSearch}
        error={gene.error}>
        <input
          type="text"
          disabled={isLoading}
          name={"gene"}
          id={"gene"}
          className={`rounded-l-lg bg-gray-100 p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none ${
            isLoading && "cursor-not-allowed"
          }`}
          placeholder={"Example: CSN3 Ovis Aries"}
          value={gene.value}
          onChange={({ target }) => setGene({ value: target.value, error: "" })}
        />
        <SearchButton isLoading={isLoading} disabled={isLoading} />
      </SearchForm>
    </article>
  );
}
